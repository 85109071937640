import React from "react";
import Layout from "../../components/layout";
import CapabilitiesHeroSection from "../../components/capabilitiesherosection";
import AuditButtons from "../../components/auditbuttons";
import SingleButton from "../../components/singlebutton";
import { Helmet } from "react-helmet";
import Audits from "../../components/audits";
import Arrow from "../../components/arrow";

import capabilityicon from "../../images/capability-ux-cx-design-2.png";

import userresearch from "../../images/community-management.png";
import uxworkshops from "../../images/social-media-training.png";
import customerpersonaandjourneymapping from "../../images/digital-activity-roadmaps.png";
import uxuidesign from "../../images/ux-cx-strategy.png";
import interactiondesign from "../../images/interaction-design.png";
import servicedesign from "../../images/service-design.png";
import analytics from "../../images/digital-audits.png";

import rowtwoheroleft from "../../images/ux-cx-design-row-two-hero-left.jpg";
import rowtwoheroright from "../../images/ux-cx-design-row-two-hero-right.jpg";

import rowfivehero from "../../images/ux-cx-design-row-five-hero.png";

import rowtenhero1 from "../../images/ux-cx-design-row-ten-hero-1.png";
import rowtenhero2 from "../../images/ux-cx-design-row-ten-hero-2.png";
import rowtenhero3 from "../../images/ux-cx-design-row-ten-hero-3.png";
import rowtenhero4 from "../../images/ux-cx-design-row-ten-hero-4.png";
import rowtenhero5 from "../../images/ux-cx-design-row-ten-hero-5.png";

import rowelevenhero from "../../images/ux-cx-design-row-eleven-hero.jpg";

import "../../scss/capabilitypage.scss";

const UxCxDesign = ({ data }) => {
    const metadescription = "Next&Co's UX and CX design services provide customer centric data informed digital experiences visit our site or call 1300 191 950";
    const metatitle = "UX Design Agency Melbourne, CX Design - Next&Co";
    const metaurl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
          <Helmet>
            <title>{metatitle}</title>
            <meta name="description" content={metadescription}/>
            <meta name="title" content={metatitle} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={metaurl} />
            <meta property="og:title" content={metatitle} />
            <meta property="og:description" content={metadescription} />
            <meta property="og:image" content="facebook.png" />
            <meta property="twitter:url" content={metaurl} />
            <meta property="twitter:title" content={metatitle} />
            <meta property="twitter:description" content={metadescription} />
            <meta property="twitter:image" content="twitter.png" />
        </Helmet>
        <Arrow/>
        <div className="capability-page-general ux-cx-design-page">
            <Layout>
		<CapabilitiesHeroSection
                  heading="UX/CX Design"
                  description="UX/CX Design – Customer centric, data-informed digital experiences"
                  icon={capabilityicon}
                  imagedesktop={data.desktop.childImageSharp.fluid}
                  imagemobile={data.mobile.childImageSharp.fluid}
		/>
		<section id="row-three">
                    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container row">
				<div className="col-one">
				    <h4 className="col-onef-heading">What we do</h4>
				</div>
				<div className="col-one">
				    <div className="list-items">
					<div className="list-item">
					    <span className="icon">
						<img alt="" src={userresearch}/>
					    </span>
					    <div className="content">
						<h3>User research</h3>
					    </div>
					</div>
					<div className="list-item">
					    <span className="icon">
						<img alt="" src={uxworkshops} />
					    </span>
					    <div className="content">
						<h3>UX workshops</h3>
					    </div>
					</div>
					<div className="list-item">
					    <span className="icon">
						<img alt="" src={customerpersonaandjourneymapping} />
					    </span>
					    <div className="content">
						<h3>Customer persona <br/> &amp; journey mapping</h3>
					    </div>
					</div>
					<div className="list-item">
					    <span className="icon">
						<img alt="" src={uxuidesign} />
					    </span>
					    <div className="content">
						<h3>UX/UI design</h3>
					    </div>
					</div>
				    </div>
				</div>
				<div className="col-one">
				    <div className="list-items">
					<div className="list-item">
					    <span className="icon">
						<img alt="" src={interactiondesign} />
					    </span>
					    <div className="content">
						<h3>Interaction design</h3>
					    </div>
					</div>
					<div className="list-item">
					    <span className="icon">
						<img alt="" src={servicedesign} />
					    </span>
					    <div className="content">
						<h3>Service design</h3>
					    </div>
					</div>
					<div className="list-item">
					    <span className="icon">
						<img alt="" src={analytics} />
					    </span>
					    <div className="content">
						<h3>Analytics</h3>
					    </div>
					</div>
				    </div>
				</div>
			    </div>
			</div>
                    </div>
		</section>
		<AuditButtons
                  title1="Let’s talk about your project."
                  linktitle1="Get in touch"
                  link1="#"
		/>
		<section id="row-two">
                    <div className="outer-container">
			<div className="container-max-width">
			    <div className="row">
				<div className="col">
				    <img alt="" src={rowtwoheroleft} />
				</div>
				<div className="col">
				    <img alt="" src={rowtwoheroright} />
				</div>
			    </div>
			</div>
                    </div>
		</section>
		<section id="row-five">
                    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container">
				<h4>Customer centricity is a must</h4>
				<p>The customers’ expectations from brand experiences have changed.  Customers want to be treated as individuals, as opposed to ‘segments’, having experiences catered to them based on who they are and what they want at any given moment.<br/> A desire to empathise with these customer expectations sits at the core of Next&amp;Co’s experience design work.</p>
				<div className="row">
				    <div className="col">
					<img alt="" src={rowfivehero} />
				    </div>
				</div>
			    </div>
			</div>
                    </div>
		</section>
		<section id="row-ten">
                    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container">
				<div className="small-inner-container">
				    <div className="col">
					<h4>Design based on insight</h4>
					<p>Our methodology puts the customers’ expectations at the centre and uses these expectations as the central truth for our experience design process.</p>
				    </div>
				</div>
				<div className="row">
				    <div className="col">
					<img alt="" src={rowtenhero1} />
					<h5>Understand the customer</h5>
					<p>Use all available research and tools to understand the customer persona and their journey</p>
				    </div>
				    <div className="col">
					<img alt="" src={rowtenhero2} />
					<h5>Define problems</h5>
					<p>Examine the current state problems that the customer has</p>
				    </div>
				    <div className="col">
					<img alt="" src={rowtenhero3} />
					<h5>Ideate solutions</h5>
					<p>Create solutions to the customer problems</p>
				    </div>
				    <div className="col">
					<img alt="" src={rowtenhero4} />
					<h5>Test &amp; benchmark solutions</h5>
					<p>Test &amp; validate the solutions</p>
				    </div>
				    <div className="col">
					<img alt="" src={rowtenhero5} />
					<h5>Optimise</h5>
					<p>Optimise the solution based on real market feedback</p>
				    </div>
				</div>
			    </div>
			</div>
                    </div>
		</section>
		<section id="row-eleven">
                    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container">
				<img alt="" src={rowelevenhero} />
			    </div>
			</div>
                    </div>
		</section>
		<section id="row-four">
                    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container">
				<div className="col-one">
				    <h3>Many of Australia's leading businesses partner with us, you should too.</h3>
				    <SingleButton buttonText="Get in touch"/>
				</div>
			    </div>
			</div>
                    </div>
		</section>
		<Audits />
            </Layout>
        </div>
    </>
  );
};

export default UxCxDesign;

export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "capabilities-desktop-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    mobile: file(relativePath: { eq: "capabilities-mobile-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
